import React from 'react'
import { Box } from '@mui/joy'
import { useTheme } from '@mui/material/styles'

const Header = () => {
  const theme = useTheme()
  const themeMode = theme.palette.mode
  return (
    <Box
      component="header"
      sx={{
        pt: 8,
        maxHeight: '160px',
      }}
    >
      <Box
        level="h1"
        textAlign="center"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {themeMode !== 'dark' ? (
          <svg
            viewBox="0 0 2380 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M295.02 427.269H280.325C181.69 427.269 101.731 347.31 101.731 248.675V101.731L0.000456572 0V248.675C0.000456572 403.494 125.506 529 280.325 529H395.62L295.02 427.269Z"
              fill="#0a7ffe"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M262.24 101.731H101.731L0.000347137 0H262.24C417.059 0 542.564 125.506 542.564 280.325V529L440.834 427.269V280.325C440.834 181.69 360.874 101.731 262.24 101.731Z"
              fill="#0a7ffe"
            />
            <path
              d="M2263.47 418.875C2242.09 418.875 2222.58 413.377 2204.92 402.38C2187.42 391.383 2173.4 376.591 2162.87 358.005C2152.49 339.418 2147.3 318.896 2147.3 296.437C2147.3 279.554 2150.32 263.756 2156.36 249.041C2162.41 234.172 2170.69 221.162 2181.22 210.01C2191.91 198.703 2204.3 189.874 2218.4 183.524C2232.49 177.174 2247.52 173.999 2263.47 173.999C2281.59 173.999 2298.16 177.871 2313.19 185.615C2328.37 193.205 2341.22 203.659 2351.75 216.98C2362.29 230.3 2369.95 245.479 2374.76 262.517C2379.56 279.554 2380.64 297.366 2378.01 315.953H2208.17C2210.34 324.626 2213.91 332.448 2218.86 339.418C2223.82 346.233 2230.09 351.732 2237.68 355.914C2245.27 359.941 2253.87 362.032 2263.47 362.186C2273.38 362.341 2282.37 360.018 2290.42 355.217C2298.63 350.26 2305.44 343.6 2310.86 335.236L2368.71 348.711C2359.27 369.311 2345.17 386.194 2326.43 399.359C2307.69 412.37 2286.7 418.875 2263.47 418.875ZM2206.32 273.436H2320.62C2318.92 264.143 2315.28 255.779 2309.7 248.344C2304.28 240.755 2297.54 234.714 2289.49 230.223C2281.44 225.731 2272.76 223.485 2263.47 223.485C2254.18 223.485 2245.58 225.731 2237.68 230.223C2229.78 234.559 2223.04 240.523 2217.47 248.112C2212.05 255.547 2208.33 263.988 2206.32 273.436Z"
              fill="black"
            />
            <path
              d="M2052.34 412.835V64.3384H2109.03V412.835H2052.34Z"
              fill="black"
            />
            <path
              d="M1939.76 180.504H1996.45V417.249C1996.45 433.977 1993.04 449.156 1986.23 462.786C1979.41 476.571 1970.12 488.342 1958.35 498.1C1946.58 508.013 1933.1 515.602 1917.92 520.869C1902.74 526.29 1886.79 529 1870.06 529C1853.8 529 1838.15 525.67 1823.13 519.01C1808.11 512.35 1794.79 503.211 1783.17 491.595C1771.71 480.133 1762.96 467.045 1756.92 452.331L1808.73 428.866C1811.82 437.384 1816.47 444.819 1822.67 451.169C1829.02 457.675 1836.3 462.709 1844.5 466.271C1852.71 469.988 1861.23 471.847 1870.06 471.847C1879.04 471.847 1887.64 470.608 1895.85 468.13C1904.21 465.651 1911.73 462.011 1918.39 457.21C1925.05 452.563 1930.23 446.833 1933.95 440.018C1937.82 433.357 1939.76 425.768 1939.76 417.249V384.49C1932.48 394.713 1923.34 402.999 1912.34 409.35C1901.35 415.7 1888.65 418.875 1874.24 418.875C1857.51 418.875 1841.87 415.7 1827.31 409.35C1812.75 402.999 1799.9 394.248 1788.74 383.096C1777.75 371.79 1769.07 358.779 1762.72 344.065C1756.53 329.351 1753.43 313.552 1753.43 296.669C1753.43 279.787 1756.53 263.988 1762.72 249.274C1769.07 234.56 1777.75 221.627 1788.74 210.475C1799.9 199.168 1812.75 190.339 1827.31 183.989C1841.87 177.639 1857.51 174.463 1874.24 174.463C1888.65 174.463 1901.35 177.639 1912.34 183.989C1923.34 190.184 1932.48 198.393 1939.76 208.616V180.504ZM1874.94 364.278C1886.87 364.278 1897.4 361.257 1906.54 355.217C1915.83 349.021 1923.11 340.812 1928.38 330.59C1933.64 320.212 1936.28 308.905 1936.28 296.669C1936.28 284.278 1933.56 272.972 1928.14 262.749C1922.88 252.527 1915.6 244.318 1906.3 238.122C1897.17 231.927 1886.71 228.829 1874.94 228.829C1863.32 228.829 1852.71 231.927 1843.11 238.122C1833.51 244.163 1825.84 252.294 1820.11 262.517C1814.38 272.739 1811.51 284.124 1811.51 296.669C1811.51 309.215 1814.38 320.599 1820.11 330.822C1825.84 341.045 1833.51 349.176 1843.11 355.217C1852.71 361.257 1863.32 364.278 1874.94 364.278Z"
              fill="black"
            />
            <path
              d="M1725.62 266.002V412.835H1668.93V280.871C1668.93 271.732 1666.69 263.446 1662.19 256.011C1657.7 248.422 1651.66 242.459 1644.07 238.122C1636.64 233.63 1628.35 231.384 1619.21 231.384C1610.07 231.384 1601.71 233.63 1594.12 238.122C1586.69 242.459 1580.72 248.422 1576.23 256.011C1571.89 263.446 1569.73 271.732 1569.73 280.871V412.835H1513.04L1512.8 180.504H1569.49L1569.73 201.181C1577.63 192.817 1587.07 186.235 1598.07 181.433C1609.07 176.477 1620.92 173.999 1633.62 173.999C1650.5 173.999 1665.91 178.18 1679.85 186.544C1693.79 194.753 1704.86 205.828 1713.07 219.768C1721.44 233.553 1725.62 248.964 1725.62 266.002Z"
              fill="black"
            />
            <path
              d="M1409.82 180.504H1466.51V412.835H1409.82L1407.26 384.026C1400.91 394.403 1392.47 402.844 1381.94 409.35C1371.56 415.7 1359.25 418.875 1345 418.875C1327.81 418.875 1311.7 415.623 1296.68 409.117C1281.65 402.612 1268.41 393.629 1256.95 382.167C1245.64 370.705 1236.73 357.462 1230.23 342.438C1223.88 327.414 1220.7 311.306 1220.7 294.114C1220.7 277.541 1223.72 261.974 1229.76 247.415C1235.96 232.856 1244.56 220.077 1255.55 209.08C1266.55 198.083 1279.25 189.487 1293.65 183.292C1308.06 177.096 1323.55 173.999 1340.12 173.999C1355.45 173.999 1369.16 177.406 1381.24 184.221C1393.48 191.036 1403.86 199.71 1412.38 210.242L1409.82 180.504ZM1343.61 364.277C1355.69 364.277 1366.37 361.257 1375.67 355.217C1384.96 349.176 1392.24 341.044 1397.51 330.822C1402.77 320.444 1405.41 309.06 1405.41 296.669C1405.41 284.123 1402.77 272.739 1397.51 262.517C1392.24 252.139 1384.88 243.93 1375.44 237.889C1366.14 231.849 1355.53 228.829 1343.61 228.829C1331.68 228.829 1320.76 231.926 1310.85 238.122C1301.09 244.162 1293.27 252.294 1287.38 262.517C1281.65 272.739 1278.79 284.123 1278.79 296.669C1278.79 309.215 1281.73 320.599 1287.61 330.822C1293.5 341.044 1301.32 349.176 1311.08 355.217C1320.99 361.257 1331.83 364.277 1343.61 364.277Z"
              fill="black"
            />
            <path
              d="M1125.77 64.3384H1182.46V412.835H1125.77V383.793C1118.34 394.171 1108.97 402.612 1097.66 409.117C1086.35 415.623 1073.26 418.875 1058.39 418.875C1041.51 418.875 1025.71 415.7 1011 409.35C996.285 402.999 983.274 394.248 971.967 383.096C960.816 371.79 952.064 358.779 945.714 344.065C939.364 329.35 936.188 313.552 936.188 296.669C936.188 279.787 939.364 263.988 945.714 249.274C952.064 234.559 960.816 221.626 971.967 210.474C983.274 199.168 996.285 190.339 1011 183.989C1025.71 177.638 1041.51 174.463 1058.39 174.463C1073.26 174.463 1086.35 177.716 1097.66 184.221C1108.97 190.571 1118.34 199.013 1125.77 209.545V64.3384ZM1059.09 364.278C1071.17 364.278 1081.86 361.257 1091.15 355.217C1100.45 349.176 1107.73 341.044 1112.99 330.822C1118.26 320.444 1120.89 309.06 1120.89 296.669C1120.89 284.123 1118.26 272.739 1112.99 262.517C1107.73 252.139 1100.37 243.93 1090.92 237.89C1081.63 231.849 1071.02 228.829 1059.09 228.829C1047.17 228.829 1036.25 231.926 1026.33 238.122C1016.57 244.162 1008.75 252.294 1002.87 262.517C997.137 272.739 994.271 284.123 994.271 296.669C994.271 309.215 997.214 320.599 1003.1 330.822C1008.99 341.044 1016.81 349.176 1026.57 355.217C1036.48 361.257 1047.32 364.278 1059.09 364.278Z"
              fill="black"
            />
            <path
              d="M851.682 180.504H908.37V412.835H851.682L849.126 384.026C842.776 394.403 834.334 402.844 823.802 409.35C813.425 415.7 801.111 418.875 786.861 418.875C769.669 418.875 753.561 415.623 738.537 409.117C723.513 402.612 710.27 393.629 698.808 382.167C687.501 370.705 678.595 357.462 672.09 342.438C665.74 327.414 662.564 311.306 662.564 294.114C662.564 277.541 665.585 261.974 671.625 247.415C677.821 232.856 686.417 220.077 697.414 209.08C708.411 198.083 721.112 189.487 735.516 183.292C749.921 177.096 765.41 173.999 781.982 173.999C797.316 173.999 811.024 177.406 823.105 184.221C835.341 191.036 845.719 199.71 854.237 210.242L851.682 180.504ZM785.467 364.277C797.549 364.277 808.236 361.257 817.529 355.217C826.822 349.176 834.102 341.044 839.368 330.822C844.634 320.444 847.267 309.06 847.267 296.669C847.267 284.123 844.634 272.739 839.368 262.517C834.102 252.139 826.745 243.93 817.297 237.889C808.004 231.849 797.394 228.829 785.467 228.829C773.541 228.829 762.622 231.926 752.709 238.122C742.951 244.162 735.129 252.294 729.243 262.517C723.513 272.739 720.647 284.123 720.647 296.669C720.647 309.215 723.59 320.599 729.476 330.822C735.361 341.044 743.183 349.176 752.941 355.217C762.854 361.257 773.696 364.277 785.467 364.277Z"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            viewBox="0 0 2380 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M295.02 427.269H280.325C181.69 427.269 101.731 347.31 101.731 248.675V101.731L0.000456572 0V248.675C0.000456572 403.494 125.506 529 280.325 529H395.62L295.02 427.269Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M262.24 101.731H101.731L0.000347137 0H262.24C417.059 0 542.564 125.506 542.564 280.325V529L440.834 427.269V280.325C440.834 181.69 360.874 101.731 262.24 101.731Z"
              fill="white"
            />
            <path
              d="M2263.47 418.875C2242.09 418.875 2222.58 413.377 2204.92 402.38C2187.42 391.383 2173.4 376.591 2162.87 358.005C2152.49 339.418 2147.3 318.896 2147.3 296.437C2147.3 279.554 2150.32 263.756 2156.36 249.041C2162.41 234.172 2170.69 221.162 2181.22 210.01C2191.91 198.703 2204.3 189.874 2218.4 183.524C2232.49 177.174 2247.52 173.999 2263.47 173.999C2281.59 173.999 2298.16 177.871 2313.19 185.615C2328.37 193.205 2341.22 203.659 2351.75 216.98C2362.29 230.3 2369.95 245.479 2374.76 262.517C2379.56 279.554 2380.64 297.366 2378.01 315.953H2208.17C2210.34 324.626 2213.91 332.448 2218.86 339.418C2223.82 346.233 2230.09 351.732 2237.68 355.914C2245.27 359.941 2253.87 362.032 2263.47 362.186C2273.38 362.341 2282.37 360.018 2290.42 355.217C2298.63 350.26 2305.44 343.6 2310.86 335.236L2368.71 348.711C2359.27 369.311 2345.17 386.194 2326.43 399.359C2307.69 412.37 2286.7 418.875 2263.47 418.875ZM2206.32 273.436H2320.62C2318.92 264.143 2315.28 255.779 2309.7 248.344C2304.28 240.755 2297.54 234.714 2289.49 230.223C2281.44 225.731 2272.76 223.485 2263.47 223.485C2254.18 223.485 2245.58 225.731 2237.68 230.223C2229.78 234.559 2223.04 240.523 2217.47 248.112C2212.05 255.547 2208.33 263.988 2206.32 273.436Z"
              fill="white"
            />
            <path
              d="M2052.34 412.835V64.3384H2109.03V412.835H2052.34Z"
              fill="white"
            />
            <path
              d="M1939.76 180.504H1996.45V417.249C1996.45 433.977 1993.04 449.156 1986.23 462.786C1979.41 476.571 1970.12 488.342 1958.35 498.1C1946.58 508.013 1933.1 515.602 1917.92 520.869C1902.74 526.29 1886.79 529 1870.06 529C1853.8 529 1838.15 525.67 1823.13 519.01C1808.11 512.35 1794.79 503.211 1783.17 491.595C1771.71 480.133 1762.96 467.045 1756.92 452.331L1808.73 428.866C1811.82 437.384 1816.47 444.819 1822.67 451.169C1829.02 457.675 1836.3 462.709 1844.5 466.271C1852.71 469.988 1861.23 471.847 1870.06 471.847C1879.04 471.847 1887.64 470.608 1895.85 468.13C1904.21 465.651 1911.73 462.011 1918.39 457.21C1925.05 452.563 1930.23 446.833 1933.95 440.018C1937.82 433.357 1939.76 425.768 1939.76 417.249V384.49C1932.48 394.713 1923.34 402.999 1912.34 409.35C1901.35 415.7 1888.65 418.875 1874.24 418.875C1857.51 418.875 1841.87 415.7 1827.31 409.35C1812.75 402.999 1799.9 394.248 1788.74 383.096C1777.75 371.79 1769.07 358.779 1762.72 344.065C1756.53 329.351 1753.43 313.552 1753.43 296.669C1753.43 279.787 1756.53 263.988 1762.72 249.274C1769.07 234.56 1777.75 221.627 1788.74 210.475C1799.9 199.168 1812.75 190.339 1827.31 183.989C1841.87 177.639 1857.51 174.463 1874.24 174.463C1888.65 174.463 1901.35 177.639 1912.34 183.989C1923.34 190.184 1932.48 198.393 1939.76 208.616V180.504ZM1874.94 364.278C1886.87 364.278 1897.4 361.257 1906.54 355.217C1915.83 349.021 1923.11 340.812 1928.38 330.59C1933.64 320.212 1936.28 308.905 1936.28 296.669C1936.28 284.278 1933.56 272.972 1928.14 262.749C1922.88 252.527 1915.6 244.318 1906.3 238.122C1897.17 231.927 1886.71 228.829 1874.94 228.829C1863.32 228.829 1852.71 231.927 1843.11 238.122C1833.51 244.163 1825.84 252.294 1820.11 262.517C1814.38 272.739 1811.51 284.124 1811.51 296.669C1811.51 309.215 1814.38 320.599 1820.11 330.822C1825.84 341.045 1833.51 349.176 1843.11 355.217C1852.71 361.257 1863.32 364.278 1874.94 364.278Z"
              fill="white"
            />
            <path
              d="M1725.62 266.002V412.835H1668.93V280.871C1668.93 271.732 1666.69 263.446 1662.19 256.011C1657.7 248.422 1651.66 242.459 1644.07 238.122C1636.64 233.63 1628.35 231.384 1619.21 231.384C1610.07 231.384 1601.71 233.63 1594.12 238.122C1586.69 242.459 1580.72 248.422 1576.23 256.011C1571.89 263.446 1569.73 271.732 1569.73 280.871V412.835H1513.04L1512.8 180.504H1569.49L1569.73 201.181C1577.63 192.817 1587.07 186.235 1598.07 181.433C1609.07 176.477 1620.92 173.999 1633.62 173.999C1650.5 173.999 1665.91 178.18 1679.85 186.544C1693.79 194.753 1704.86 205.828 1713.07 219.768C1721.44 233.553 1725.62 248.964 1725.62 266.002Z"
              fill="white"
            />
            <path
              d="M1409.82 180.504H1466.51V412.835H1409.82L1407.26 384.026C1400.91 394.403 1392.47 402.844 1381.94 409.35C1371.56 415.7 1359.25 418.875 1345 418.875C1327.81 418.875 1311.7 415.623 1296.68 409.117C1281.65 402.612 1268.41 393.629 1256.95 382.167C1245.64 370.705 1236.73 357.462 1230.23 342.438C1223.88 327.414 1220.7 311.306 1220.7 294.114C1220.7 277.541 1223.72 261.974 1229.76 247.415C1235.96 232.856 1244.56 220.077 1255.55 209.08C1266.55 198.083 1279.25 189.487 1293.65 183.292C1308.06 177.096 1323.55 173.999 1340.12 173.999C1355.45 173.999 1369.16 177.406 1381.24 184.221C1393.48 191.036 1403.86 199.71 1412.38 210.242L1409.82 180.504ZM1343.61 364.277C1355.69 364.277 1366.37 361.257 1375.67 355.217C1384.96 349.176 1392.24 341.044 1397.51 330.822C1402.77 320.444 1405.41 309.06 1405.41 296.669C1405.41 284.123 1402.77 272.739 1397.51 262.517C1392.24 252.139 1384.88 243.93 1375.44 237.889C1366.14 231.849 1355.53 228.829 1343.61 228.829C1331.68 228.829 1320.76 231.926 1310.85 238.122C1301.09 244.162 1293.27 252.294 1287.38 262.517C1281.65 272.739 1278.79 284.123 1278.79 296.669C1278.79 309.215 1281.73 320.599 1287.61 330.822C1293.5 341.044 1301.32 349.176 1311.08 355.217C1320.99 361.257 1331.83 364.277 1343.61 364.277Z"
              fill="white"
            />
            <path
              d="M1125.77 64.3384H1182.46V412.835H1125.77V383.793C1118.34 394.171 1108.97 402.612 1097.66 409.117C1086.35 415.623 1073.26 418.875 1058.39 418.875C1041.51 418.875 1025.71 415.7 1011 409.35C996.285 402.999 983.274 394.248 971.967 383.096C960.816 371.79 952.064 358.779 945.714 344.065C939.364 329.35 936.188 313.552 936.188 296.669C936.188 279.787 939.364 263.988 945.714 249.274C952.064 234.559 960.816 221.626 971.967 210.474C983.274 199.168 996.285 190.339 1011 183.989C1025.71 177.638 1041.51 174.463 1058.39 174.463C1073.26 174.463 1086.35 177.716 1097.66 184.221C1108.97 190.571 1118.34 199.013 1125.77 209.545V64.3384ZM1059.09 364.278C1071.17 364.278 1081.86 361.257 1091.15 355.217C1100.45 349.176 1107.73 341.044 1112.99 330.822C1118.26 320.444 1120.89 309.06 1120.89 296.669C1120.89 284.123 1118.26 272.739 1112.99 262.517C1107.73 252.139 1100.37 243.93 1090.92 237.89C1081.63 231.849 1071.02 228.829 1059.09 228.829C1047.17 228.829 1036.25 231.926 1026.33 238.122C1016.57 244.162 1008.75 252.294 1002.87 262.517C997.137 272.739 994.271 284.123 994.271 296.669C994.271 309.215 997.214 320.599 1003.1 330.822C1008.99 341.044 1016.81 349.176 1026.57 355.217C1036.48 361.257 1047.32 364.278 1059.09 364.278Z"
              fill="white"
            />
            <path
              d="M851.682 180.504H908.37V412.835H851.682L849.126 384.026C842.776 394.403 834.334 402.844 823.802 409.35C813.425 415.7 801.111 418.875 786.861 418.875C769.669 418.875 753.561 415.623 738.537 409.117C723.513 402.612 710.27 393.629 698.808 382.167C687.501 370.705 678.595 357.462 672.09 342.438C665.74 327.414 662.564 311.306 662.564 294.114C662.564 277.541 665.585 261.974 671.625 247.415C677.821 232.856 686.417 220.077 697.414 209.08C708.411 198.083 721.112 189.487 735.516 183.292C749.921 177.096 765.41 173.999 781.982 173.999C797.316 173.999 811.024 177.406 823.105 184.221C835.341 191.036 845.719 199.71 854.237 210.242L851.682 180.504ZM785.467 364.277C797.549 364.277 808.236 361.257 817.529 355.217C826.822 349.176 834.102 341.044 839.368 330.822C844.634 320.444 847.267 309.06 847.267 296.669C847.267 284.123 844.634 272.739 839.368 262.517C834.102 252.139 826.745 243.93 817.297 237.889C808.004 231.849 797.394 228.829 785.467 228.829C773.541 228.829 762.622 231.926 752.709 238.122C742.951 244.162 735.129 252.294 729.243 262.517C723.513 272.739 720.647 284.123 720.647 296.669C720.647 309.215 723.59 320.599 729.476 330.822C735.361 341.044 743.183 349.176 752.941 355.217C762.854 361.257 773.696 364.277 785.467 364.277Z"
              fill="white"
            />
          </svg>
        )}
      </Box>
    </Box>
  )
}

export default Header
