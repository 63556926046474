import React, { useMemo, useState } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Input } from 'antd'
import { Add, BookmarksRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import FormItem from '../../../layout/FormItem'
import { toast } from '../../../layout/JoyToaster'
import { debounce } from '@mui/material'
import { isEnglish } from '../../../../store/utils/englishValidation'

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)
  const { set, arr } = values

  const showWarning = useMemo(
    () =>
      debounce(() => {
        toast.warn(t('only_english'))
      }, 150),
    [t],
  )

  const onChangeName = (e) => {
    const val = e.target.value
    if (!isEnglish(val)) {
      showWarning()
      return
    }
    set({
      ...arr,
      ...{ name: e.target.value },
    })
  }

  const onChangeKey = (e) => {
    const val = e.target.value
    if (!isEnglish(val)) {
      showWarning()
      return
    }
    set({
      ...arr,
      ...{ param_key: e.target.value },
    })
  }

  const validateOnPaste = (e) => {
    if (!isEnglish(e.clipboardData.getData('text'))) {
      showWarning()
      return
    }
  }

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <BookmarksRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_category_basic')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <FormItem
          text={'name'}
          component={
            <Input
              style={{ width: '100%' }}
              value={arr.name}
              onChange={onChangeName}
              onPaste={validateOnPaste}
            />
          }
        />

        <FormItem
          text={'value'}
          component={
            <Input
              style={{ width: '100%' }}
              onChange={(e) => {
                set({
                  ...arr,
                  ...{ param_value: e.target.value },
                })
              }}
            />
          }
        />

        <FormItem
          text={'key'}
          component={
            <Input
              value={arr.param_key}
              style={{ width: '100%' }}
              onChange={onChangeKey}
              onPaste={validateOnPaste}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
