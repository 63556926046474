import React, { useState } from 'react'
import { Add, PersonRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/joy'
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import FormItem from '../../../layout/FormItem'

const SettingsForm = ({ values }) => {
  const { t } = useTranslation()
  const { arr, set } = values
  const [index, setIndex] = useState(0)
  const toggleCol = (col) => (e) => {
    const copy = { ...arr.statistic_params }
    copy[col] = e.target.checked
    set({ ...arr, statistic_params: copy })
  }

  return (
    <Accordion
      expanded={index === 0}
      sx={{ borderBottom: 0 }}
      onChange={(event, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('statistic_settings')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          {Object.entries(arr.statistic_params).map(([col, value]) => (
            <FormItem
              key={col}
              htmlFor={`statistic_params.${col}`}
              text={`statistic_params.${col}`}
              sx2={{ display: 'flex', alignItems: 'center' }}
              sx={{ textAlign: 'end', padding: '0 0 4px 0' }}
              component={
                <Checkbox
                  id={`statistic_params.${col}`}
                  value={value}
                  checked={value}
                  onChange={toggleCol(col)}
                />
              }
            />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default SettingsForm
