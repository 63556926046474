import { Box } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Chips from './Chips'
import DateInputs from './DateInputs'
import GroupBy from './GroupBy'
import ReportType from './ReportType'
import VpnTorBtn from './Vpn'

import { Button } from 'antd'

import { useContext } from 'react'
import { useAppDispatch } from '../../../../../../store'
import { filterRequest } from '../../../../../../store/statistic'
import { FiltersContext } from '../../../../../hocs/FiltersProvider'
import { useFilter } from '../../../../../hooks/filter'
import { useReport } from '../../../../../hooks/report'
import { useTab } from '../../../../../hooks/tab'
import { random } from '../../../../Rotation/Edit/Modal/utils'

const Utility = ({ withAdvancedSearch, setWithAdvancedSearch }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { filter } = useFilter()
  const { updateReport } = useReport()
  const { updateTab, setCurrentTab } = useTab()
  const user_id = useSelector(
    (state) => state.auth.profileData.profile?.user_id,
  )
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )

  const { valueVpn, setterVpn, valueTor, setterTor, valueUniq, setterUniq } =
    useContext(FiltersContext)

  const runReport = () => {
    const reportKey = random()
    const reportName = random()

    setCurrentTab(reportKey)

    dispatch(
      filterRequest({
        user_id,
        report_id: reportName,
        withAdvancedSearch: withAdvancedSearch !== 0,
        options: filter,
      }),
    ).then((item) => {
      updateReport(reportName, item[reportName])
    })

    updateTab(reportKey, {
      key: reportKey,
      name: reportName,
    })
    setWithAdvancedSearch(withAdvancedSearch)
  }

  return (
    <>
      <Chips withAdvancedSearch={withAdvancedSearch} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          px: 3,
          alignItems: 'center',
        }}
      >
        <DateInputs />

        <Button
          onClick={runReport}
          type={'primary'}
          style={{ marginLeft: '8px' }}
        >
          {t('run_report')}
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center',
          py: 2,
          px: 3,
        }}
      >
        <ReportType
          withAdvancedSearch={withAdvancedSearch}
          setWithAdvancedSearch={setWithAdvancedSearch}
        />
        <GroupBy withAdvancedSearch={withAdvancedSearch} />
      </Box>

      {(statParams.vpn || withAdvancedSearch === 1) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gridGap: 16,
            mb: 2,
            overflowX: 'auto',
          }}
        >
          {statParams.vpn && (
            <VpnTorBtn id="vpn" val={valueVpn} setter={setterVpn} />
          )}
          {withAdvancedSearch === 1 && (
            <>
              <VpnTorBtn id="unique" val={valueUniq} setter={setterUniq} />
              <VpnTorBtn id="tor" val={valueTor} setter={setterTor} />
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default Utility
