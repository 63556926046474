import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Modal, ModalClose, ModalDialog } from '@mui/joy'
import DialogContent from '@mui/joy/DialogContent'
import DialogTitle from '@mui/joy/DialogTitle'
import Divider from '@mui/joy/Divider'
import { AutoComplete, Button, Input } from 'antd'
import { useCallback, useRef, useState } from 'react'

import { Box } from '@mui/joy'
import { debounce } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import * as users from '../../../../api/users/get'

const CopySettings = ({ values }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedUserId = useRef(-1)
  const [isError, setIsError] = useState(false)

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (search) => {
      try {
        const res = await users.getAll({ search, active: -1, role_id: -1 })
        if (res.data.error) {
          return toast.error(t('error'))
        }
        setRows(
          res.data.rows.map((e) => ({ value: e.email, user_id: e.user_id })),
        )
      } catch (e) {
        toast.error(t('error'))
      } finally {
        setIsLoading(false)
      }
    }, 200),
    [],
  )

  const onSearchUser = (email) => {
    if (!email) {
      return setRows([])
    }
    setIsLoading(true)
    debouncedSearch(email)
  }

  const copyUserSettings = async () => {
    if (selectedUserId.current === -1) return setIsError(true)
    setIsLoading(true)
    try {
      const res = await users.get({ user_id: selectedUserId.current })
      if (res.data.error) {
        return toast.error(t('error'))
      }
      values.set({ ...values.arr, statistic_params: res.data.statistic_params })
      setOpen(false)
    } catch (e) {
      toast.error(t('error'))
    } finally {
      setIsLoading(false)
    }
  }

  const onSelect = (_, { user_id }) => {
    setIsError(false)
    selectedUserId.current = user_id
  }

  return (
    <>
      <Modal
        onClose={() => {
          setOpen(false)
          selectedUserId.current = -1
        }}
        open={!!open}
        sx={{ zIndex: 10001 }}
      >
        <ModalDialog
          sx={{ width: '600px' }}
          variant="outlined"
          role="alertdialog"
        >
          <DialogTitle>{t('select_user')}</DialogTitle>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Divider sx={{ mt: 1 }} />
          <DialogContent>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <AutoComplete
                onSearch={onSearchUser}
                onSelect={onSelect}
                options={rows}
                style={{ flex: 1 }}
                popupClassName="max-z"
              >
                <Input
                  status={isError ? 'error' : undefined}
                  placeholder={t('select_user')}
                  prefix={<UserOutlined />}
                  suffix={isLoading && <LoadingOutlined />}
                />
              </AutoComplete>
              <Button
                size="middle"
                onClick={copyUserSettings}
                loading={isLoading}
                type="primary"
                htmlType={'button'}
                variant="solid"
              >
                {t('copy_settings')}
              </Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Button
        size="small"
        onClick={() => setOpen(true)}
        loading={isLoading}
        type="default"
        htmlType={'button'}
        variant="solid"
      >
        {t('copy_settings')}
      </Button>
    </>
  )
}

export default CopySettings
