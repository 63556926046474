import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/joy'
import { toast } from '../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { customParamAdd, customParamGetAll } from '../../../../store/custom'
import { useCookies } from 'react-cookie'
import Form from './Form'
import Submit from './Submit'
import { useSelector } from 'react-redux'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const defaultFormValues = { name: '', param_value: '', param_key: '' }

  const [, setCookie] = useCookies(['reload'])
  const [formValues, setFormValues] = useState(defaultFormValues)

  const existingCustomParams = useSelector(
    (state) => state.custom.customParamsGetAllData?.rows,
  )

  const customParamData = useRef(
    new Map([
      ['names', new Set()],
      ['keys', new Set()],
      ['values', new Set()],
    ]),
  )

  const checkExistingCustomParams = () => {
    existingCustomParams.forEach((param) => {
      customParamData.current.get('names').add(param.name)
      customParamData.current.get('keys').add(param.param_key)
      customParamData.current.get('values').add(param.param_value)
    })
  }

  useEffect(() => {
    if (!existingCustomParams.length) {
      dispatch(customParamGetAll())
    } else {
      checkExistingCustomParams()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, existingCustomParams])

  const checkIfCustomParamExists = (param) => {
    if (customParamData.current.get('names').has(param.name)) {
      toast.error(t('add_custom_param_name_exists'))
      return true
    }

    if (customParamData.current.get('keys').has(param.param_key)) {
      toast.error(t('add_custom_param_key_exists'))
      return true
    }

    if (customParamData.current.get('values').has(param.param_value)) {
      toast.error(t('add_custom_param_value_exists'))
      return true
    }

    return false
  }

  const handlerForm = (event) => {
    event.preventDefault()
    if (checkIfCustomParamExists(formValues)) return
    dispatch(customParamAdd(formValues)).then((result) => {
      result
        ? toast.success(t('add_custom_param_added'))
        : toast.error(t('error'))

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={{ set: setFormValues, arr: formValues }} />
        <Submit />
      </form>
    </Box>
  )
}

export default Add
