import React, { useState } from 'react'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { ConfigProvider, DatePicker } from 'antd'
import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'
const { RangePicker } = DatePicker

const DateInputs = () => {
  const { i18n } = useTranslation()
  const { filter, updateFilter } = useFilter()

  const dateFormat = 'YYYY-MM-DD HH'

  dayjs.extend(utc)
  const [dateRange, setDateRange] = useState([
    filter.date_from ? dayjs(filter.date_from, dateFormat) : dayjs().utc(),
    filter.date_to ? dayjs(filter.date_to, dateFormat) : dayjs().utc(),
  ])

  const [tempDateRange, setTempDateRange] = useState(dateRange)

  const reorderDates = (start, end) => {
    if (start.isAfter(end)) {
      return [end, start]
    }
    return [start, end]
  }

  const handleDatesChange = (_, dateString) => {
    const [from, to] = dateString
    if (from && to) {
      updateFilter('date_from', from.format(dateFormat) + ':00:00')
      updateFilter('date_to', to.format(dateFormat) + ':59:59')
      setDateRange([from, to])
    }
  }

  const handleCalendarChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      let [startDate, endDate] = reorderDates(dates[0], dates[1])
      setTempDateRange([startDate, endDate])
    }
  }

  const areDateRangesEqual = (dateRange, tempDateRange) => {
    return (
      dateRange[0].isSame(tempDateRange[0]) &&
      dateRange[1].isSame(tempDateRange[1])
    )
  }

  const handleBlur = () => {
    if (areDateRangesEqual(dateRange, tempDateRange)) {
      return
    }
    if (tempDateRange[0] && tempDateRange[1]) {
      let [startDate, endDate] = [tempDateRange[0], tempDateRange[1]]

      setDateRange([startDate, endDate])
      updateFilter('date_from', startDate.format(dateFormat) + ':00:00')
      updateFilter('date_to', endDate.format(dateFormat) + ':59:59')
    }
  }

  return (
    <>
      <ConfigProvider locale={i18n.language === 'ru' ? localeRu : {}}>
        <RangePicker
          style={{ flex: 1 }}
          value={tempDateRange}
          defaultValue={dateRange}
          format={dateFormat}
          onChange={handleDatesChange}
          onCalendarChange={handleCalendarChange}
          onBlur={handleBlur}
          showTime={{ format: 'HH' }}
        />
      </ConfigProvider>
    </>
  )
}

export default DateInputs
