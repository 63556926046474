import { useEffect, useState } from 'react'

import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../store'
import { customParamGetAll } from '../../../../../../store/custom'
import { useFilter } from '../../../../../hooks/filter'

const excludedFiltersForGeneralSearch = [
  'connection_type',
  'publisher',
  'referer',
  'custom',
]

const excludedFiltersForAvandcedSearch = ['template']

const GroupBy = ({ withAdvancedSearch }) => {
  const { t } = useTranslation()
  const groupByText = t('group_by', { returnObjects: true })
  const dispatch = useAppDispatch()
  const { filter, replaceItemFromFilter } = useFilter()
  const [groupBy, setGroupBy] = useState(filter.group_by || [])
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )
  const cp = useSelector((state) => state.custom.customParamsGetAllData?.rows)

  useEffect(() => {
    if (!cp.length) {
      dispatch(customParamGetAll())
    }
  }, [dispatch, cp])

  useEffect(() => {
    if (withAdvancedSearch === 0) {
      setGroupBy((prev) => {
        const newValues = prev.filter(
          (option) => !excludedFiltersForGeneralSearch.includes(option),
        )
        replaceItemFromFilter('group_by', newValues)
        return newValues
      })
    }
  }, [withAdvancedSearch, replaceItemFromFilter])

  let options = Object.entries(groupByText).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  if (withAdvancedSearch === 0) {
    options = options.filter(
      (option) => !excludedFiltersForGeneralSearch.includes(option.value),
    )
  } else {
    options = options.filter(
      (option) => !excludedFiltersForAvandcedSearch.includes(option.value),
    )
  }

  cp &&
    cp.forEach((i) => {
      options.push({ value: `CP${i.param_key}`, label: i.name })
    })

  options = options.filter((e) => {
    switch (e.value) {
      case 'browser':
        return statParams.browser_name
      case 'operating':
        return statParams.os_name
      case 'format':
        return statParams.device_format
      case 'vendor':
        return statParams.device_vendor
      default:
        return statParams[e.value] !== false
    }
  })

  return (
    <>
      <Select
        defaultValue={groupBy}
        maxTagCount={'responsive'}
        allowClear
        mode="multiple"
        style={{ flex: 1 }}
        loading={!options.length}
        options={options}
        value={groupBy}
        onChange={(newValue) => {
          replaceItemFromFilter('group_by', newValue)
          setGroupBy(newValue)
        }}
      />
    </>
  )
}

export default GroupBy
