import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/joy/styles'
import { JoyToaster } from './layout/JoyToaster'
import CssBase from './handlers/CssBase'
import Pages from './pages'

import { ConfigProvider, theme } from 'antd'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useAppDispatch } from '../store'
import { getProfile } from '../store/auth/actionCreators'
import { useSelector } from 'react-redux'

export default function App() {
  const dispatch = useAppDispatch()
  const [mounted, setMounted] = useState(false)
  const { defaultAlgorithm, darkAlgorithm } = theme
  const user = useSelector((state) => ({
    ...state.auth.profileData,
    data: state.auth.profileData,
    error: !!state.auth.profileData?.error,
  }))

  const mode = useTheme().palette.mode

  useEffect(() => {
    dispatch(getProfile())
    setMounted(true)
  }, [dispatch])

  const router = createBrowserRouter([
    { path: '*', element: <Pages profile={user} /> },
  ])

  return (
    <ConfigProvider
      theme={{
        algorithm: mode === 'dark' ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <JoyToaster />
      <CssBase />
      {mounted && <RouterProvider router={router} />}
    </ConfigProvider>
  )
}
