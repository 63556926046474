import React, { useState } from 'react'

import { Box } from '@mui/joy'
import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../store'
import { zoneAdd, zoneGetAll, zoneSetFetched } from '../../../../../store/zones'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const pageId = parseInt(useParams().pageId)

  const defaultFormValues = {
    publisher_id: pageId,
    rotation_id: null,
    active: null,
    name: '',
  }

  const [, setCookie] = useCookies(['reload'])
  const [formValues, setFormValues] = useState(defaultFormValues)

  const handlerForm = (event) => {
    event.preventDefault()
    dispatch(zoneAdd(formValues)).then((result) => {
      result ? toast.success(t('add_zone_added')) : toast.error(t('error'))
      setCookie('reload', new Date().getTime(), { path: '/' })
      dispatch(zoneGetAll({ active: 1, publisher_id: pageId })).then(
        (result) => {
          if (result.data.rows) {
            dispatch(zoneSetFetched({ [pageId]: result.data.rows }))
          }
        },
      )
    })
  }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={{ set: setFormValues, arr: formValues }} />
        <Submit />
      </form>
    </Box>
  )
}

export default Add
