import React, { createContext, useCallback, useContext, useState } from 'react'
export const RotationContext = createContext()

export function RotationProvider({ children }) {
  const [rtt, setRtt] = useState({})

  const updateRotation = (key, newValue) => {
    setRtt((current) => ({ ...current, [key]: newValue }))
  }

  const replaceRotation = useCallback((newValue) => setRtt(newValue), [setRtt])

  return (
    <RotationContext.Provider value={{ rtt, updateRotation, replaceRotation }}>
      {children}
    </RotationContext.Provider>
  )
}

export function useRtt() {
  const context = useContext(RotationContext)
  if (!context) {
    throw new Error('useRtt must be used within a RotationContext')
  }
  return context
}
