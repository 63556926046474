import React from 'react'

import { Box } from '@mui/joy'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CopySettings from '../Shared/CopySettings'

const Submit = ({ values }) => {
  const { t } = useTranslation()
  const isLoading = useSelector((state) => state.users.editData?.isLoading)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', mr: 2, mt: 2, gap: 1 }}>
      <CopySettings values={values} />
      <Button
        size="small"
        loading={isLoading}
        type="primary"
        htmlType={'submit'}
        variant="solid"
      >
        {t('edit_advertiser_submit')}
      </Button>
    </Box>
  )
}

export default Submit
